<template>
  <el-dialog :visible.sync="visible"
             :close-on-click-modal="false"
             :before-close="handleClose"
             class="avue-dialog"
             :title="title || '数据处理'"
             width="80%">
    <div class="event">
      <div class="item">
        <div class="label">大屏对象：
          <el-tooltip content="需要操作其他大屏对象，选择后会拿到对应的对象id"
                      placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="value">
          <avue-select :dic="contain.list"
                       size="mini"
                       @change="handleCopy"
                       v-model="select"
                       placeholder="请选择大屏对象"
                       :props="{label:'name',value:'index'}">
          </avue-select>
        </div>
      </div>
      <div class="item">
        <el-button type="primary"
                   size="mini"
                   @click="$refs.codeTip.open()"
                   icon="el-icon-notebook-2">说明</el-button>
      </div>
    </div>
    <div class="content">
      <monaco-editor v-model="code"
                     :language="language"></monaco-editor>
    </div>
    <span class="avue-dialog__footer avue-dialog__footer--right">
      <el-button size="small"
                 @click="setVisible(false)">取 消</el-button>
      <el-button type="primary"
                 @click="submit"
                 size="small">确 定</el-button>
    </span>
    <code-tip ref="codeTip"
              :type="type"></code-tip>
  </el-dialog>
</template>

<script>
import { funEval } from '@/utils/utils'
import MonacoEditor from '@/page/components/monaco-editor'
import codeTip from './code-tip'
export default {
  inject: ["contain"],
  components: { MonacoEditor, codeTip },
  data () {
    return {
      select: '',
      code: ''
    }
  },
  props: {
    rules: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: 'javascript'
    },
    title: String,
    visible: Boolean,
    type: String,
    value: [String, Object, Array]
  },
  watch: {
    value: {
      handler (val) {
        if (this.validatenull(val)) {
          if (['dataFormatter', 'stylesFormatter'].includes(this.type) && this.validatenull(val)) {
            this.code = `(data,params,refs)=>{
    return {}
}`
          } else if (['query', 'header', 'dataQuery', 'dataHeader'].includes(this.type) && this.validatenull(val)) {
            this.code = `(data)=>{
    return {}
}`
          } else if (['echartFormatter'].includes(this.type) && this.validatenull(val)) {
            this.code = `(data)=>{
    const myChart = this.myChart;
    const option={}
    return option
}`
          } else if (['clickFormatter', 'dblClickFormatter', 'mouseEnterFormatter', 'mouseLeaveFormatter'].includes(this.type) && this.validatenull(val)) {
            this.code = `(params,refs)=>{
    console.log(params,refs)
}`
          } else if (['labelFormatter', 'formatter'].includes(this.type) && this.validatenull(val)) {
            this.code = `(name,data)=>{
    console.log(name,data)
    return ''
}`
          } else {
            this.code = val;
          }
        } else {
          this.code = val;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleCopy (val) {
      if (!val.value) return
      this.$Clipboard({
        text: `refs['${val.value}']`,
      }).then(() => {
        this.$message.success('复制成功')
      }).catch(() => {
        this.$message.error('复制失败')
      });
    },
    handleClose () {
      this.setVisible(false);
    },
    submit () {
      let value = this.code;
      if (!this.rules) {
        this.$emit('submit', value);
        this.setVisible(false)
        return
      }
      try {
        funEval(value);
        if (['data'].includes(this.type)) value = funEval(value);
        this.$emit('submit', value);
        this.setVisible(false)
      } catch (error) {
        console.log(error);
        this.$message.error('数据格式有误')
      }

    },
    setVisible (val) {
      this.$emit('update:visible', val);
    }
  }
}
</script>
<style lang="scss" scoped>
.event {
  display: flex;
  padding: 0 25px 15px 25px;
  .item {
    margin-right: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .label {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
}
.content {
  display: flex;
  .monaco_editor_container {
    flex: 1;
    &:first-child {
      flex: 2;
    }
  }
}
</style>
